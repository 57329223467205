













































import type { FooterOverlay } from '@/components/chrome/footer/Overlay.vue'
import { defineComponent, ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'MagazineStories',
  components: {},
  props: {},

  setup() {
    const { chrome } = useGetters(['chrome'])
    const popupContentRef = ref<FooterOverlay | null>(null)

    const openPopup = () => {
      popupContentRef.value = chrome.value.ctas.stories.overlay
    }

    const closePopup = () => {
      popupContentRef.value = null
    }

    return {
      chrome,
      popupContentRef,
      openPopup,
      closePopup,
    }
  },
})
